/* tv displays*/

/* UTILITY */
.tv_text_no_wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.center_align_elements {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active_page_dot {
    width: 0.9375vw; //18px; //36px
    height: 1.67vh; //18px; //36px
    border-radius: 50%;
    background-color: #cfff04;
}

.inactive_page_dot {
    width: 0.625vw; //12px; //24px
    height: 1.12vh; //12px; //24px
    border-radius: 50%;
    background-color: #84878B;
}

.tv-inprogress {
    background-color: #CFFF04;
    color: #21262D;
}

.tv-finished {
    background-color: #21262D;
    color: #4F7630;
}
/* UTILITY END */

/* FONTS */
.helvetica_bold_56 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.6vh;//28px; //60px;
    font-weight: bold;
}

.helvetica_bold_60 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.77vh;//30px; //60px;
    font-weight: bold;
}

.helvetica_bold_70 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.25vh;//35px; //70px;
    font-weight: bold;
}

.helvetica_bold_100 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4.63vh;///50px; //100px;
    font-weight: bold;
}

.helvetica_regular_40 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.85vh;//20px; //40px;
    font-weight: normal;
}

.helvetica_regular_56 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.6vh;//28px; //56px;
    font-weight: normal;
}

.helvetica_regular_60 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.77vh; //30px; //60px;
    font-weight: normal;
}

.helvetica_regular_64 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.96vh;//32px; //64px;
    font-weight: normal;
}

.helvetica_regular_74 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.43vh; //37px; //74px;
    font-weight: normal;
}
/* FONTS END */

/* COLORS */
.tv_color_main_bg {
    background-color: #CFFF04;
}

.tv_color_main {
    color: #CFFF04;
}

.tv_darkgreen_bg {
    background-color: #5F7630;
}

.tv_darkgreen {
    color: #5F7630
}

.tv_white_bg {
    background-color: #FFF;
}

.tv_white {
    color: #FFF;
}

.tv_white2_bg {
    background-color: #DADADA;
}

.tv_white2 {
    color: #DADADA;
}

.tv_grey_bg {
    background-color: #14171B;
}

.tv_grey {
    color: #14171B;
}

.tv_grey2_bg {
    background-color: #2F353D;
}

.tv_grey2 {
    color: #2F353D;
}

.tv_grey3_bg {
    background-color: #21262D;
}

.tv_grey3 {
    color: #21262D;
}

.tv_grey4_bg {
    background-color: #84878B;
}

.tv_grey4 {
    color: #84878B;
}
/* COLORS END */

/* BASE */
.base_border {
    border: 2px solid rgba(255,255,255,0.6); //2px
}

.border_top {
    border-top: 2px solid rgba(255,255,255,0.6); //2px
}

.border_left {
    border-left: 2px solid rgba(255,255,255,0.6); //2px
}

.border_right {
    border-right: 2px solid rgba(255,255,255,0.6); //2px
}

.border_bottom {
    border-bottom: 2px solid rgba(255,255,255,0.6); //2px
}

.line_height1 {
    line-height: 1;
}

.line_height128 {
    line-height: 5.925vh; //64px; //128px
}

.text_align_center {
    text-align: center;
}

.text_align_right {
    text-align: right;
}

.flex1{
    flex: 1;
}
/* BASE */


/* TOPBAR */
.topbar_size{
    width: 100vw; //3840px
    height: 9.22vh; //200px
}

.topbar_logo{
    width: 10vw;
}

.topbar_logo_img {
    width: 6.46vw; //124px; //248px
    height: 2.96vh; //32px; //64px
}

.topbar_title {
    height: 9.22vh; //100px; //200px
    width: 90vw; // 1520px; // 3040px
    padding-left: 2.08vw; //40px; //80px
}

.topbar_time {
    width: 10vw;
    padding-right: 2.08vw; //40px; //80px
}
/* TOPBAR */

/* SIDEMENU RINGS */
.tv_sidebar {
    width: 10vw;
    height: 90.78vh; //980px; //1960px
    margin-top: 9.25vh; //100px; //200px
    padding-top: 2.96vh; //32px; //64px
    padding-left: 0.42vw; //8px; //16px
    padding-right: 0.42vw; //16px
}

.tv-sidebar-menu-item {
    height: 5.925vh; //64px; //128px
}

.tv-sidebar-menu-item.active {
    color: #14171B;
    background-color: #CFFF04;
    font-weight: bold;
}
/* SIDEMENU RINGS */

/* PAGINATION */

.pagination {
    height: 10vh;
    width: 100%;
}

/* PAGINATION END */

/* RING INFO */
.ring_judge_info{
    width: 85vw;
    margin-left:12.5vw;
}

.ring_info_text{
    flex: 1;
    width: 50%;
}

.ring_judge_text {
    flex: 1;
    width: 50%;
    text-align: right;
}

/* RING INFO END */

/* MAIN BODY */
.tv_main_content {
    height: 90.78vh; //height: 980px; // 1960px
}

.tv_main_table {
    width: 85.47vw; //1641px; //3282px
    margin-left: 12.5vw; //240px; //480px
    border: 0;
}

.tv_breed {
    width: 41.71vw; //801px; //1602px
}

.tv_p_breed {
    width: 39.16vw; //801px; //1602px
}

.tv_result_breed {
    width: 40.16vw;//771px; //1542px
}

.tv_result_p_breed {
    width: 37.61vw; //650px; //1300px
}

.tv_img_breed {
    height: 4.54vh; //49px; //98px
    width: 2.55vw; //49px; //98px
}

.tv_timetable {
    width: 16.15vw;// 310px; //620px
}

.tv_fromto {
    width: 15.10vw; //290px; //580px
}

.tv_total {
    width: 12.5vw; //240px; // 480px
}

/* MAIN BODY END */

.bob-result-box {
    background-color: #cfff04;
    color: #21262D;
    font-size: 2.78vh; //30px;
    width: 5.88vw;//113px;
    height: 6vh; //65px;
    margin-left: 0.83vw; //16px;
}

.result-box {
    border: 2px solid #cfff04;
    color: #FFFFFF;
    font-size: 2.78vh; //30px;
    width: 5.88vw; //113px;
    height: 6vh; //65px;
    margin-left: 0.83vw; //16px;
}


/* BIS SCHEDULE */

.tv_bis_table {
    width: 95.42vw; //1832px; //3664px
    margin-left: 2.08vw; //40px; // 80px
    margin-top: 3.7vh;//40px; // 80px
    margin-right: 2.08vw;//40px; // 80px
    border: 0;
}

/* HEIGHTS */
.tv150height {
    height: 6.95vh;//75px; //150px
}

.tv226height{
    height: 10.46vh; //113px; //226px
}

.tv1506height {
    height: 70vh;
    //height: 753px; //1506px
}
/* HEIGHTS END*/

/* WIDTHS */
.tv1220width {
    width: 31.77vw;//610px; //1220px
}

.tv1520width{
    width: 39.58vw;//760px; // 1520px
}

.tv620width {
    width: 16.15vw;//310px; // 620px
}

.tv290width {
    width: 7.55vw;//145px; //290px
}
/* WIDTHS END */

/* PADDINGS */
.padding_right40 {
    padding-right: 1.04vw; //20px; //40px
}

.padding_right64 {
    padding-right: 0.83vw;//32px; //64px
}

.padding_right80 {
    padding-right: 2.08vw; //40px; //80px
}

.padding_left64 {
    padding-left: 0.83vw; //32px //64px
}
/* PADDINGS END*/

