table {
  &.dataTable {
    &.no-footer {
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;
    }
  }
}

.sorting_asc {
  &:focus {
    outline: none;
  }
}

.dataTables_wrapper {
  overflow: hidden;
  padding-bottom: 5px;

  .dataTables_length{
    color: $default-dark;
    float: left;

    @include to($breakpoint-sm) {
      text-align: left;
    }

    select {
      border: 1px solid $border-color;
      border-radius: 2px;
      box-shadow: none;
      height: 35px;
      font-size: 14px;
      padding: 5px;
      margin-left: 5px;
      margin-right: 5px;
      color: $default-text-color;
      transition: all 0.2s ease-in;
    }
  }

  .dataTables_filter {
    color: $default-dark;
    float: right;

    @include to($breakpoint-sm) {
      text-align: left;
    }

    input {
      border: 1px solid $border-color;
      border-radius: 2px;
      box-shadow: none;
      height: 35px;
      font-size: 14px;
      margin-left: 15px;
      padding: 5px;
      color: $default-text-color;
      transition: all 0.2s ease-in;
    }
  }

  .dataTables_info {
    color: $default-text-color;
    float: left;
  }

  .dataTables_processing {
    color: $default-dark;
  }

  .dataTables_paginate {
    color: $default-text-color;
    float: right;

    .paginate_button {
      color: $default-text-color !important;
      padding: 6px 12px;
      border-radius: 2px;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &.next,
      &.previous,
      &.first,
      &.last {
        border-radius: 2px;
        text-decoration: none;

        &:hover,
        &:focus {
          color: #fff !important;
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      &:hover {
        color: #fff !important;
        background: $default-primary;
      }

      &.current {
        color: #fff !important;
        background: $default-primary;

        &:hover {
          color: $default-white !important;
          background: $default-primary;
        }
      }
    }
  }

  .status {
    width: 5px;
    height: 5px;
  }
}

