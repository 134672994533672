// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Variables
// + @Base
// + @Gap (0 > 4 Step 1)
// + @Gap (5 > 35 Step 5)
// + @Gap (40 > 160 Step 10)

// ---------------------------------------------------------
// @Variables
// ---------------------------------------------------------

$responsive: true;

// ---------------------------------------------------------
// @Base
// ---------------------------------------------------------

[class*='gap'] {
  width: auto !important;
  overflow: hidden !important;
}

// ---------------------------------------------------------
// @Gap (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
  .gapX-#{$i} {
    margin-left: #{calc($i / -2)}px !important;
    margin-right: #{calc($i / -2)}px !important;

    & > * {
      padding-left: #{calc($i / 2)}px !important;
      padding-right: #{calc($i / 2)}px !important;
    }
  }

  .gapY-#{$i} {
    margin-top: #{calc($i / -2)}px !important;
    margin-bottom: #{calc($i / -2)}px !important;

    & > * {
      padding-top: #{calc($i / 2)}px !important;
      padding-bottom: #{calc($i / 2)}px !important;
    }
  }

  .gap-#{$i} {
    margin: #{calc($i / -2)}px !important;

    & > * {
      padding: #{calc($i / 2)}px !important;
    }
  }

  @if ($responsive == true) {
    @include generateResponsive() {
        .gapX-#{$i}\@#{$breakpointAlias} {
            margin-left: #{calc($i / -2)}px !important;
            margin-right: #{calc($i / -2)}px !important;

            & > * {
                padding-left: #{calc($i / 2)}px !important;
                padding-right: #{calc($i / 2)}px !important;
            }
        }

        .gapY-#{$i}\@#{$breakpointAlias} {
            margin-top: #{calc($i / -2)}px !important;
            margin-bottom: #{calc($i / -2)}px !important;

            & > * {
                padding-top: #{calc($i / 2)}px !important;
                padding-bottom: #{calc($i / 2)}px !important;
            }
        }

        .gap-#{$i}\@#{$breakpointAlias} {
            margin: #{calc($i / -2)}px !important;

            & > * {
                padding: #{calc($i / 2)}px !important;
            }
        }
    }
  }
}

// ---------------------------------------------------------
// @Gap (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
  @if $i % 5 == 0 {
    .gapX-#{$i} {
      margin-left: #{calc($i / -2)}px !important;
      margin-right: #{calc($i / -2)}px !important;

      & > * {
        padding-left: #{calc($i / 2)}px !important;
        padding-right: #{calc($i / 2)}px !important;
      }
    }

    .gapY-#{$i} {
      margin-top: #{calc($i / -2)}px !important;
      margin-bottom: #{calc($i / -2)}px !important;

      & > * {
        padding-top: #{calc($i / 2)}px !important;
        padding-bottom: #{calc($i / 2)}px !important;
      }
    }

    .gap-#{$i} {
      margin: #{calc($i / -2)}px !important;

      & > * {
        padding: #{calc($i / 2)}px !important;
      }
    }

    @if ($responsive == true) {
      @include generateResponsive() {
        .gapX-#{$i}\@#{$breakpointAlias} {
          margin-left: #{calc($i / -2)}px !important;
          margin-right: #{calc($i / -2)}px !important;

          & > * {
            padding-left: #{calc($i / 2)}px !important;
            padding-right: #{calc($i / 2)}px !important;
          }
        }

        .gapY-#{$i}\@#{$breakpointAlias} {
          margin-top: #{calc($i / -2)}px !important;
          margin-bottom: #{calc($i / -2)}px !important;

          & > * {
            padding-top: #{calc($i / 2)}px !important;
            padding-bottom: #{calc($i / 2)}px !important;
          }
        }

        .gap-#{$i}\@#{$breakpointAlias} {
          margin: #{calc($i / -2)}px !important;

          & > * {
            padding: #{calc($i / 2)}px !important;
          }
        }
      }
    }
  }
}

// ---------------------------------------------------------
// @Gap (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
  @if $i % 10 == 0 {
    .gapX-#{$i} {
        margin-left: #{calc($i / -2)}px !important;
        margin-right: #{calc($i / -2)}px !important;

        & > * {
            padding-left: #{calc($i / 2)}px !important;
            padding-right: #{calc($i / 2)}px !important;
        }
    }

    .gapY-#{$i} {
        margin-top: #{calc($i / -2)}px !important;
        margin-bottom: #{calc($i / -2)}px !important;

        & > * {
            padding-top: #{calc($i / 2)}px !important;
            padding-bottom: #{calc($i / 2)}px !important;
        }
    }

    .gap-#{$i} {
        margin: #{calc($i / -2)}px !important;

        & > * {
            padding: #{calc($i / 2)}px !important;
        }
    }

    @if ($responsive == true) {
        @include generateResponsive() {
            .gapX-#{$i}\@#{$breakpointAlias} {
                margin-left: #{calc($i / -2)}px !important;
                margin-right: #{calc($i / -2)}px !important;

                & > * {
                    padding-left: #{calc($i / 2)}px !important;
                    padding-right: #{calc($i / 2)}px !important;
                }
            }

            .gapY-#{$i}\@#{$breakpointAlias} {
                margin-top: #{calc($i / -2)}px !important;
                margin-bottom: #{calc($i / -2)}px !important;

                & > * {
                    padding-top: #{calc($i / 2)}px !important;
                    padding-bottom: #{calc($i / 2)}px !important;
                }
            }

            .gap-#{$i}\@#{$breakpointAlias} {
                margin: #{calc($i / -2)}px !important;

                & > * {
                    padding: #{calc($i / 2)}px !important;
                }
            }
        }
    }
  }
}
