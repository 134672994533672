.peers {
  box-sizing: border-box;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.peer {
  display: block;
  height: auto;
  flex: 0 0 auto;
}

.peer-greed {
  flex: 1 1 auto;
  // overflow: hidden;
}

.peers-greed > .peer,
.peers-greed > .peers {
  flex: 1 1 auto;
}

.peer > img {
  max-width: none;
}

.peer-greed > img {
  max-width: 100%;
}
